
import { computed, defineComponent } from 'vue'
import { Ref } from 'vue/dist/vue'

import { config } from '@/config'
import { injectStrict } from '@/utils/injection'

export default defineComponent({
  name: 'TheFooter',
  setup() {
    const isLoginPage: Ref<boolean> = injectStrict('isLoginPage')
    const appVersion = computed(() => config.VERSION)
    return {
      isLoginPage,
      appVersion,
    }
  },
})
