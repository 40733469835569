import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b9c915a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alert-component" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_header = _resolveComponent("the-header")!
  const _component_the_side_bar = _resolveComponent("the-side-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_the_footer = _resolveComponent("the-footer")!
  const _component_loading_component = _resolveComponent("loading-component")!
  const _component_alert_component = _resolveComponent("alert-component")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_the_header),
    _createVNode(_component_the_side_bar),
    _createElementVNode("main", {
      class: _normalizeClass([_ctx.isLoginPage ? 'login-main' : ''])
    }, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ]),
        _: 1
      })
    ], 2),
    _createVNode(_component_the_footer),
    (_ctx.globalValue.isLoading)
      ? (_openBlock(), _createBlock(_component_loading_component, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.globalValue.isError)
        ? (_openBlock(), _createBlock(_component_alert_component, {
            key: 0,
            variant: _ctx.globalValue.errorType,
            message: _ctx.globalValue.errorMessage,
            title: _ctx.globalValue.errorType.toUpperCase()
          }, null, 8, ["variant", "message", "title"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}