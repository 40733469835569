import './index.css'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Oruga from '@oruga-ui/oruga-next'
import { BrowserTracing } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import { createHead } from '@vueuse/head'
import { LicenseManager } from 'ag-grid-enterprise'
import * as firebase from 'firebase/app'
import { createApp } from 'vue'
// @ts-ignore
import InstantSearch from 'vue-instantsearch/vue3/es'

import { config } from '@/config'
import { firebaseConfig } from '@/utils/firebase'

import App from './App.vue'
import router from './router'
import { store } from './store'

// Firebase
firebase.initializeApp(firebaseConfig)

LicenseManager.setLicenseKey(config.AG_GRID_LICENSE)

// FontAwesome
library.add(fas)
library.add(far)
dom.watch()

const app = createApp(App)

Sentry.init({
  app,
  dsn: config.ENVIRONMENT !== 'development' ? config.SENTRY_DSN : null,
  environment: config.ENVIRONMENT,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost:8080', 'my-site-url.com', /^\//],
    }),
  ],
  tracesSampleRate: 0.9,
})

const head = createHead()
app
  .use(store)
  .use(router)
  .use(Oruga, {
    iconPack: 'fas far',
  })
  .use(head)
  .use(InstantSearch)

app.mount('#app')
