<template>
  <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 358.36 179.13">
    <path class="b" d="M164.49,25.7c0,2.19-1.78,3.97-3.98,3.97s-3.98-1.78-3.98-3.97,1.78-3.98,3.98-3.98,3.98,1.78,3.98,3.98Z" />
    <path class="b" d="M147.92,133.72c-2.2,0-3.98,1.78-3.98,3.98s1.78,3.97,3.98,3.97,3.98-1.78,3.98-3.97-1.78-3.98-3.98-3.98Z" />
    <path class="b" d="M192.5,152.5c0,.21,0,.42-.03,.63-3.05,.53-6.16,.88-9.32,1.05v-10.13c.28-.03,.56-.04,.84-.04,4.7,0,8.5,3.81,8.5,8.5Z" />
    <path
      class="e"
      d="M219.63,11.65C208.93,5.03,196.49,.96,183.16,.28V60.06c6.68-12.16,18.29-21.22,32.14-24.49-.78-2.47-1.19-5.1-1.19-7.83,0-6.07,2.06-11.65,5.53-16.09Zm-21.35,14.05c-2.2,0-3.98-1.78-3.98-3.98s1.78-3.98,3.98-3.98,3.97,1.78,3.97,3.98-1.78,3.98-3.97,3.98Z"
    />
    <path class="b" d="M202.26,21.72c0,2.2-1.78,3.98-3.97,3.98s-3.98-1.78-3.98-3.98,1.78-3.98,3.98-3.98,3.97,1.78,3.97,3.98Z" />
    <path
      class="b"
      d="M245.41,37.84c-5.75-2.33-12.04-3.61-18.63-3.61-1.1,0-2.19,.04-3.28,.11-.81-2.05-1.25-4.27-1.25-6.61,0-4.34,1.54-8.33,4.09-11.44,7.62,5.91,14.11,13.22,19.07,21.54Z"
    />
    <circle class="b" cx="196.96" cy="77.22" r="3.98" transform="translate(76.55 251.17) rotate(-76.72)" />
    <path
      class="b"
      d="M251.74,51.26c-.07-.19-.13-.37-.2-.56-.29-.81-.61-1.62-.93-2.41-6.81-4.36-14.91-6.88-23.59-6.88-7.76,0-15.05,2.01-21.37,5.55-.02,0-.04,.02-.06,.03-13.38,7.51-22.42,21.84-22.42,38.27v50.99c8.81,.1,16.1,6.54,17.53,14.98,6.38-1.85,12.42-4.5,17.99-7.84V77.22c0-2.2,1.78-3.97,3.97-3.97s3.98,1.77,3.98,3.97v60.71c1.8-1.41,3.54-2.9,5.2-4.46,1.87-1.76,3.66-3.6,5.34-5.53,.7-.8,1.39-1.62,2.06-2.44,10.63-13.2,16.99-29.99,16.99-48.27,0-9.1-1.58-17.85-4.49-25.96Zm-54.78,29.94c-2.2,0-3.98-1.78-3.98-3.98s1.78-3.97,3.98-3.97,3.97,1.77,3.97,3.97-1.77,3.98-3.97,3.98Z"
    />
    <circle class="b" cx="196.96" cy="77.22" r="3.98" transform="translate(76.55 251.17) rotate(-76.72)" />
    <circle class="b" cx="196.96" cy="77.22" r="3.98" transform="translate(76.55 251.17) rotate(-76.72)" />
    <path
      class="d"
      d="M139.4,47.15h11.21c3.07,0,6.01-.56,8.72-1.58,2.24-.84,4.33-2,6.2-3.41,5.24-3.97,8.86-9.96,9.67-16.82V.28c-3.75,.2-7.45,.66-11.04,1.37-14.7,2.9-27.9,9.99-38.3,19.97-2.79,2.68-5.38,5.56-7.75,8.63-.5,.65-1,1.32-1.48,1.99-1.89,2.61-3.61,5.35-5.17,8.2-5.94,10.93-9.33,23.47-9.33,36.79,0,15.94,4.85,30.75,13.14,43.04,5.02,2,10.5,3.1,16.23,3.1,22.9,0,41.7-17.54,43.7-39.91V45.16c-1.09,1.2-2.28,2.32-3.54,3.34-1.77,1.45-3.7,2.71-5.75,3.76-3.91,2.03-8.27,3.3-12.9,3.62h-.01c-.79,.06-1.58,.09-2.39,.09h-11.21c-1.21,0-2.32-.49-3.12-1.29-.67-.68-1.13-1.57-1.25-2.57-.02-.18-.04-.36-.04-.55,0-2.43,1.98-4.41,4.41-4.41Zm21.11-25.43c2.2,0,3.98,1.78,3.98,3.98s-1.78,3.97-3.98,3.97-3.98-1.78-3.98-3.97,1.78-3.98,3.98-3.98Z"
    />
    <path class="b" d="M164.49,25.7c0,2.19-1.78,3.97-3.98,3.97s-3.98-1.78-3.98-3.97,1.78-3.98,3.98-3.98,3.98,1.78,3.98,3.98Z" />
    <path class="b" d="M175.45,.27h-.25v-.27c.09,.09,.17,.18,.25,.27Z" />
    <path class="b" d="M164.49,25.7c0,2.19-1.78,3.97-3.98,3.97s-3.98-1.78-3.98-3.97,1.78-3.98,3.98-3.98,3.98,1.78,3.98,3.98Z" />
    <path
      class="b"
      d="M245.41,37.84c-5.75-2.33-12.04-3.61-18.63-3.61-1.1,0-2.19,.04-3.28,.11-.81-2.05-1.25-4.27-1.25-6.61,0-4.34,1.54-8.33,4.09-11.44,7.62,5.91,14.11,13.22,19.07,21.54Z"
    />
    <path
      class="e"
      d="M245.41,37.84c-5.75-2.33-12.04-3.61-18.63-3.61-1.1,0-2.19,.04-3.28,.11-.81-2.05-1.25-4.27-1.25-6.61,0-4.34,1.54-8.33,4.09-11.44,7.62,5.91,14.11,13.22,19.07,21.54Z"
    />
    <path
      class="e"
      d="M132.34,130.92c-3.15,0-6.23-.29-9.22-.84,10.95,11.61,25.48,19.82,41.82,22.88,3.35,.63,6.77,1.03,10.25,1.21v-46.96c-8.95,14.24-24.8,23.71-42.86,23.71Zm15.58,10.76c-2.2,0-3.98-1.78-3.98-3.97s1.78-3.98,3.98-3.98,3.98,1.78,3.98,3.98-1.78,3.97-3.98,3.97Zm17.64,0h-5.04c-2.2,0-3.98-1.78-3.98-3.97s1.78-3.98,3.98-3.98h5.04c2.2,0,3.98,1.78,3.98,3.98s-1.78,3.97-3.98,3.97Z"
    />
    <path class="b" d="M192.5,152.5c0,.21,0,.42-.03,.63-3.05,.53-6.16,.88-9.32,1.05v-10.13c.28-.03,.56-.04,.84-.04,4.7,0,8.5,3.81,8.5,8.5Z" />
    <path class="b" d="M192.5,152.5c0,.21,0,.42-.03,.63-3.05,.53-6.16,.88-9.32,1.05v-10.13c.28-.03,.56-.04,.84-.04,4.7,0,8.5,3.81,8.5,8.5Z" />
    <path class="c" d="M202.26,21.72c0,2.2-1.78,3.98-3.97,3.98s-3.98-1.78-3.98-3.98,1.78-3.98,3.98-3.98,3.97,1.78,3.97,3.98Z" />
    <path class="c" d="M164.49,25.7c0,2.19-1.78,3.97-3.98,3.97s-3.98-1.78-3.98-3.97,1.78-3.98,3.98-3.98,3.98,1.78,3.98,3.98Z" />
    <path class="c" d="M151.9,137.7c0,2.19-1.78,3.97-3.98,3.97s-3.98-1.78-3.98-3.97,1.78-3.98,3.98-3.98,3.98,1.78,3.98,3.98Z" />
    <path class="c" d="M169.53,137.7c0,2.19-1.78,3.97-3.98,3.97h-5.04c-2.2,0-3.98-1.78-3.98-3.97s1.78-3.98,3.98-3.98h5.04c2.2,0,3.98,1.78,3.98,3.98Z" />
    <circle class="b" cx="196.96" cy="77.22" r="3.98" transform="translate(76.55 251.17) rotate(-76.72)" />
    <path class="b" d="M218.74,143.35c-.04,.21-.06,.43-.06,.65v-.61s.04-.02,.06-.03Z" />
    <path
      class="c"
      d="M248.58,43.73c.72,1.5,1.4,3.01,2.02,4.56-.85-.54-1.72-1.05-2.61-1.54-.75-.41-1.51-.79-2.28-1.15-5.67-2.69-12.01-4.19-18.7-4.19-1.94,0-3.85,.13-5.72,.37-1.88,.25-3.71,.61-5.51,1.08-.9,.24-1.78,.5-2.65,.79-2.61,.88-5.12,1.99-7.49,3.31-.01,0-.02,0-.03,.02s-.02,.02-.03,.02c-2.09,1.18-4.08,2.52-5.94,4-.37,.3-.74,.6-1.1,.91-.72,.62-1.43,1.26-2.11,1.93-.34,.33-.68,.67-1.01,1.01-4.29,4.45-7.65,9.82-9.76,15.77-1.63,4.58-2.51,9.51-2.51,14.65v50.98h.01c8.82,.11,16.1,6.55,17.53,14.98-2.68,.78-5.42,1.41-8.21,1.89,.01-.2,.02-.41,.02-.62,0-4.7-3.8-8.5-8.5-8.5-.29,0-.57,.01-.85,.04v10.13c-1.31,.06-2.64,.1-3.97,.1-1.18,0-2.35-.03-3.51-.08-.16-.01-.31-.01-.47-.02v-46.96c-8.95,14.25-24.8,23.71-42.86,23.71-3.15,0-6.22-.29-9.21-.84-2.88-3.04-5.51-6.32-7.86-9.81,5.02,2,10.5,3.1,16.23,3.1,22.9,0,41.7-17.54,43.7-39.91V45.16c-1.09,1.2-2.28,2.32-3.54,3.34-1.77,1.45-3.7,2.71-5.75,3.76-3.91,2.03-8.27,3.3-12.9,3.62h-.01c-.79,.06-1.58,.09-2.39,.09h-11.21c-1.21,0-2.32-.49-3.12-1.29-.67-.68-1.13-1.57-1.25-2.57-.02-.18-.04-.36-.04-.55,0-2.43,1.98-4.41,4.41-4.41h11.21c3.07,0,6.01-.56,8.72-1.58,2.24-.84,4.33-2,6.2-3.41,5.24-3.97,8.86-9.96,9.67-16.82V.28c.09,0,.17,0,.25,0,1.24-.06,2.48-.09,3.73-.09,1.33,0,2.66,.04,3.97,.1V60.06c6.68-12.16,18.29-21.22,32.14-24.49-.77-2.47-1.19-5.1-1.19-7.83,0-6.07,2.07-11.65,5.53-16.09,2.33,1.43,4.56,2.98,6.71,4.65-2.55,3.11-4.09,7.09-4.09,11.44,0,2.33,.44,4.56,1.25,6.6,1.08-.07,2.17-.1,3.28-.1,6.59,0,12.87,1.27,18.63,3.6,1.14,1.92,2.2,3.88,3.17,5.89Z"
    />
    <path class="b" d="M175.45,.27h-.25v-.27c.09,.09,.17,.18,.25,.27Z" />
    <circle class="c" cx="196.96" cy="77.22" r="3.98" transform="translate(76.55 251.17) rotate(-76.72)" />
    <path
      class="c"
      d="M226.63,77.22v60.71c-.98,.76-1.97,1.5-2.99,2.22-1.59,1.13-3.23,2.2-4.91,3.21-.02,0-.04,.02-.06,.03V77.22c0-2.2,1.78-3.97,3.97-3.97s3.98,1.77,3.98,3.97Z"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LogoIcon',
})
</script>

<style scoped lang="css">
.b {
  fill: #fff;
}
.c {
  fill: #1e1e1f;
}
.d {
  fill: #7a63ff;
}
.e {
  fill: #73e1ee;
}
</style>
