
import { computed, defineComponent, PropType, toRefs } from 'vue'

export default defineComponent({
  name: 'AlertComponent',
  props: {
    variant: {
      type: String as PropType<'success' | 'info' | 'danger' | 'warning'>,
      default: 'info',
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { variant } = toRefs(props)
    const variantClass = computed(() => `${variant.value}-alert`)
    return {
      variantClass,
    }
  },
})
