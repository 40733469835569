
import { useHead } from '@vueuse/head'
import { computed, defineComponent, onMounted, provide, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import AlertComponent from '@/components/global/Alert.vue'
import LoadingComponent from '@/components/global/Loading.vue'
import TheFooter from '@/components/layout/TheFooter.vue'
import TheHeader from '@/components/layout/TheHeader.vue'
import TheSideBar from '@/components/layout/TheSideBar.vue'
import globalConfig from '@/composables/globalConfig'
import { getSettingsLocal } from '@/utils/localStorage'

export default defineComponent({
  name: 'App',
  components: { AlertComponent, LoadingComponent, TheFooter, TheSideBar, TheHeader },
  setup() {
    // Router
    const router = useRouter()
    const currentRoute = computed(() => router.currentRoute.value.path)
    const currentRouteName = computed(() => String(router.currentRoute.value.name))
    // Watch currentRoute mutate
    watch(currentRoute, () => {
      isLoginPage.value = currentRoute.value === '/login' || currentRoute.value === '/forgot-password'
    })
    // Check is Login page
    const isLoginPage = ref<boolean>(false)
    provide('isLoginPage', isLoginPage)
    // Global config
    const { value: globalValue } = globalConfig()
    // Get settings from localStorage
    onMounted(() => {
      getSettingsLocal()
    })
    // Head meta
    useHead({
      title: computed(() => `${currentRouteName.value} - Shosha Procurement system`),
    })
    return {
      globalValue,
      isLoginPage,
    }
  },
})
