import dayjs from 'dayjs'

import { authService } from '@/services/auth.service'
import { useStore } from '@/store'
import { MutationTypes as UserMutationType } from '@/store/modules/user/mutation-types'

const store = useStore()

export const checkLastLoginAt = async (lastLoginAt: string) => {
  const now = dayjs()
  const lastLoginAtDay = dayjs(parseInt(lastLoginAt, 10))
  const diff = now.diff(lastLoginAtDay, 'd')
  if (diff >= 1) {
    await authService.signOut()
  }
}

export const checkTokenExpire = async (tokenExpireAt: number): Promise<string | null> => {
  let token = ''
  const tokenExpire = dayjs(tokenExpireAt)
  const now = dayjs()
  const diff = now.diff(tokenExpire, 'minute')
  // If token expires in 10 minutes
  if (diff >= -10) {
    const newToken = await authService.getToken()
    if (newToken) {
      token = newToken
    }
  }
  return token === '' ? null : token
}

export const getTokenIndexedDB = async (): Promise<string> => {
  const fBaseStore = window.indexedDB.open('firebaseLocalStorageDb')
  return new Promise((resolve, reject) => {
    fBaseStore.onsuccess = () => {
      const db = fBaseStore.result
      const stores = 'firebaseLocalStorage'
      const tx = db.transaction(stores)
      const req = tx.objectStore(stores).getAll()
      req.onsuccess = async () => {
        if (req.result.length === 0) {
          await authService.signOut()
        } else if (req.result.length > 0) {
          const userIndex = req.result.findIndex((item) => item.fbase_key.includes('firebase:authUser'))
          if (userIndex !== -1) {
            const user = req.result[userIndex].value
            store.commit(UserMutationType.SET_USER, user)
            let token = user.stsTokenManager.accessToken
            // Check last login at
            await checkLastLoginAt(user.lastLoginAt)
            // Check token expire
            const newToken = await checkTokenExpire(user.stsTokenManager.expirationTime)
            if (newToken) {
              token = newToken
            }
            resolve(token)
          } else {
            resolve('')
          }
        }
      }
    }
    fBaseStore.onerror = (err) => {
      console.log('getTokenIndexedDB', err)
      reject(err)
    }
  })
}
