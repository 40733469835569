import { CommitOptions, Module, Store as VuexStore } from 'vuex'

import { RootState } from '@/store'
import { Getters, getters } from '@/store/modules/user/getters'
import { Mutations, mutations } from '@/store/modules/user/mutations'
import { State, state } from '@/store/modules/user/state'

export type UserStore<S = State> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(key: K, payload: P, options?: CommitOptions): ReturnType<Mutations[K]>
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>
  }
}

export const store: Module<State, RootState> = {
  state: state,
  getters: getters,
  mutations: mutations,
}
