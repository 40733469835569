<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.001 22.7499C17.381 22.7499 15.851 21.9099 14.991 20.5299C14.531 19.8199 14.271 18.9699 14.251 18.0999C14.221 16.6399 14.8411 15.2799 15.9511 14.3599C16.7811 13.6699 17.8211 13.2799 18.8911 13.2499C20.1911 13.2399 21.361 13.6899 22.281 14.5699C23.201 15.4499 23.721 16.6299 23.741 17.8999C23.761 18.7699 23.541 19.6199 23.101 20.3699C22.861 20.7899 22.551 21.1799 22.181 21.5099C21.361 22.2799 20.261 22.7299 19.091 22.7499C19.071 22.7499 19.041 22.7499 19.001 22.7499ZM19.001 14.7499C18.981 14.7499 18.951 14.7499 18.931 14.7499C18.191 14.7699 17.491 15.0299 16.911 15.5099C16.151 16.1399 15.731 17.0699 15.751 18.0699C15.761 18.6599 15.941 19.2399 16.251 19.7299C16.861 20.7099 17.921 21.3099 19.061 21.2499C19.851 21.2299 20.6011 20.9299 21.1711 20.3999C21.4311 20.1699 21.641 19.9099 21.801 19.6299C22.101 19.1099 22.251 18.5299 22.241 17.9399C22.221 17.0699 21.871 16.2599 21.241 15.6599C20.641 15.0699 19.841 14.7499 19.001 14.7499Z"
    />
    <path
      d="M18.4489 19.7499C18.2589 19.7499 18.0789 19.6799 17.9289 19.5399L16.9189 18.5799C16.6189 18.2899 16.6089 17.8199 16.8989 17.5199C17.1889 17.2199 17.6589 17.2099 17.9589 17.4999L18.4489 17.9699L20.0189 16.4499C20.3189 16.1599 20.7889 16.1699 21.0789 16.4699C21.3689 16.7699 21.3589 17.24 21.0589 17.53L18.9689 19.5499C18.8189 19.6799 18.6289 19.7499 18.4489 19.7499Z"
    />
    <path
      d="M11.9994 13.2999C11.8694 13.2999 11.7394 13.2699 11.6194 13.1999L2.78936 8.0899C2.42936 7.8799 2.30934 7.41987 2.51934 7.05987C2.72934 6.69987 3.18936 6.57985 3.53936 6.78985L11.9893 11.6799L20.3894 6.81988C20.7494 6.60988 21.2094 6.7399 21.4094 7.0899C21.6194 7.4499 21.4894 7.90987 21.1394 8.11987L12.3694 13.1999C12.2594 13.2599 12.1294 13.2999 11.9994 13.2999Z"
    />
    <path
      d="M12 22.3598C11.59 22.3598 11.25 22.0198 11.25 21.6098V12.5398C11.25 12.1298 11.59 11.7898 12 11.7898C12.41 11.7898 12.75 12.1298 12.75 12.5398V21.6098C12.75 22.0198 12.41 22.3598 12 22.3598Z"
    />
    <path
      d="M11.9989 22.7499C11.1189 22.7499 10.2389 22.5599 9.5589 22.1699L4.2189 19.2099C2.7689 18.4099 1.62891 16.4799 1.62891 14.8199V9.1599C1.62891 7.4999 2.7689 5.57994 4.2189 4.76994L9.5589 1.80992C10.9189 1.03992 13.0589 1.03992 14.4289 1.80992L19.7689 4.76994C21.2189 5.56994 22.3589 7.4999 22.3589 9.1599V14.8199C22.3589 14.9199 22.3589 14.9999 22.3389 15.0999C22.2889 15.3599 22.0989 15.5799 21.8489 15.6599C21.5989 15.7499 21.3189 15.6899 21.1089 15.5199C19.9589 14.5199 18.1789 14.4799 16.9689 15.4499C16.1989 16.0599 15.7489 16.9899 15.7489 17.9799C15.7489 18.5699 15.9089 19.1499 16.2189 19.6499C16.2989 19.7899 16.3789 19.8999 16.4689 20.0099C16.6189 20.1799 16.6789 20.4099 16.6389 20.6299C16.5989 20.8499 16.4589 21.0399 16.2589 21.1499L14.4289 22.1599C13.7489 22.5599 12.8789 22.7499 11.9989 22.7499ZM11.9989 2.74992C11.3789 2.74992 10.7489 2.87993 10.2989 3.12993L4.95889 6.08995C3.98889 6.61995 3.1489 8.0599 3.1489 9.1599V14.8199C3.1489 15.9199 3.99889 17.3599 4.95889 17.8899L10.2989 20.8499C11.2089 21.3599 12.7989 21.3599 13.7089 20.8499L14.8289 20.2299C14.4589 19.5599 14.2589 18.7799 14.2589 17.9799C14.2589 16.5199 14.9089 15.1699 16.0389 14.2699C17.3989 13.1799 19.3389 12.9499 20.8689 13.5999V9.13994C20.8689 8.03994 20.0189 6.59993 19.0589 6.06993L13.7189 3.10991C13.2489 2.87991 12.6189 2.74992 11.9989 2.74992Z"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ContainerIcon',
})
</script>

<style scoped></style>
