import { CommitOptions, Module, Store as VuexStore } from 'vuex'

import { RootState } from '@/store'

import { Getters, getters } from './getters'
import { Mutations, mutations } from './mutations'
import { State, state } from './state'

export type SettingStore<S = State> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(key: K, payload: P, options?: CommitOptions): ReturnType<Mutations[K]>
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>
  }
}

export const store: Module<State, RootState> = {
  state: state,
  getters: getters,
  mutations: mutations,
}
