import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50ac0132"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer-content" }
const _hoisted_2 = { class: "text-xs text-gray-400 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[_ctx.isLoginPage ? '!px-0' : ''], "footer-container"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", {
        class: _normalizeClass(["footer-desc", [_ctx.isLoginPage ? '!text-white' : '']])
      }, "Developed by Jayden Digital Ltd", 2),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.appVersion), 1)
    ])
  ], 2))
}