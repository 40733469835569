import { Container, PurchaseOrder, PurchaseOrderItem } from '@/types'

interface TransformOrderTypeItem {
  id: number
  title: string
  value: number
}

export interface TransformOrderType {
  name: string
  sent: TransformOrderTypeItem
  pending: TransformOrderTypeItem
  approved: TransformOrderTypeItem
}

/**
 * Currency formatter
 * @param value
 */
export const currencyFormatter = (value: number): string => {
  const formatter = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 0,
  })
  return formatter.format(value)
}
/**
 * Quantity formatter
 * @param value
 */
export const quantityFormatter = (value: number): string => new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 }).format(value)

/**
 * Transform order status
 * - Only used on Dashboard
 * @param order
 * @param title
 */
export const transformOrderStatus = (order: PurchaseOrder[] | Container[], title: string): TransformOrderType => {
  let isSent = 0
  let isPending = 0
  let isApproved = 0
  order.forEach((item) => {
    if (item.is_sent && item.is_approved) {
      isSent++
    } else if (!item.is_approved) {
      isPending++
    } else if (item.is_approved && !item.is_sent) {
      isApproved++
    }
  })
  return {
    name: title,
    sent: {
      id: 3,
      title: 'Sent',
      value: isSent,
    },
    pending: {
      id: 1,
      title: 'Pending',
      value: isPending,
    },
    approved: {
      id: 2,
      title: 'Approved',
      value: isApproved,
    },
  }
}

/**
 * Check entered quantity is valid
 * @param items
 */
export const checkUploadQuantity = async (items: PurchaseOrderItem[]): Promise<boolean> => {
  for (const item of items) {
    const quantity = parseInt(item.order_quantity_string, 10)
    if (quantity === 0 || item.order_quantity_string === undefined) {
      alert(`${item.name} is empty`)
      return false
    }
  }
  return true
}
