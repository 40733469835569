import { createLogger, createStore } from 'vuex'

import { SettingStore, store as settings } from '@/store/modules/settings'
import { State as SettingsState } from '@/store/modules/settings/state'
import { store as user, UserStore } from '@/store/modules/user'
import { State as UserState } from '@/store/modules/user/state'

export type RootState = {
  user: UserState
  settings: SettingsState
}
export type Store = UserStore<Pick<RootState, 'user'>> & SettingStore<Pick<RootState, 'settings'>>

// Plug in logger when in development environment
const debug = process.env.NODE_ENV !== 'production'
const plugins = debug ? [createLogger({})] : []

export const store = createStore({
  plugins,
  modules: {
    user,
    settings,
  },
})

export function useStore(): Store {
  return store as Store
}
