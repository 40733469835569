import { MutationTree } from 'vuex'

import { MutationTypes } from './mutation-types'
import { State } from './state'

export type Mutations<S = State> = {
  [MutationTypes.SET_IS_EXPAND](state: S, payload: boolean): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_IS_EXPAND]: (state, payload) => {
    state.isExpand = payload
    localStorage.setItem('settings', JSON.stringify({ isExpand: payload }))
  },
}
