export const userRoles = [
  {
    name: 'Super Admin',
    value: 0,
  },
  {
    name: 'Admin',
    value: 1,
  },
  {
    name: 'User',
    value: 2,
  },
]
