import firebase from 'firebase/compat'

export type State = {
  user: firebase.User | null
  token: string | null
  role: 0 | 1 | 2
}

export const state: State = {
  user: null,
  token: null,
  role: 2,
}
