
import { ChevronDoubleRightIcon } from '@heroicons/vue/outline'
import { computed, defineComponent, Ref, ref } from 'vue'
import { useRouter } from 'vue-router'

import LogoutIcon from '@/components/icons/LogoutIcon.vue'
import ContainerIcon from '@/components/icons/navigation/ContainerIcon.vue'
import DashboardIcon from '@/components/icons/navigation/DashboardIcon.vue'
import LogoIcon from '@/components/icons/navigation/LogoIcon.vue'
import OnTheWayIcon from '@/components/icons/navigation/OnTheWayIcon.vue'
import PurchaseOrderIcon from '@/components/icons/navigation/PurchaseOrderIcon.vue'
import ReadyToPickupIcon from '@/components/icons/navigation/ReadyToPickupIcon.vue'
import SettingIcon from '@/components/icons/navigation/SettingIcon.vue'
import { authService } from '@/services/auth.service'
import { store } from '@/store'
import { MutationTypes } from '@/store/modules/settings/mutation-types'
import { injectStrict } from '@/utils/injection'

const navList = [
  {
    icon: DashboardIcon,
    desc: 'Dashboard',
    link: '/',
  },
  {
    icon: PurchaseOrderIcon,
    desc: 'Purchase Order',
    link: '/purchase-order',
  },
  {
    icon: ReadyToPickupIcon,
    desc: 'Ready To Pick',
    link: '/ready-to-pick',
  },
  {
    icon: ContainerIcon,
    desc: 'Container',
    link: '/container',
  },
  {
    icon: OnTheWayIcon,
    desc: 'On The Way',
    link: '/on-the-way',
  },
  {
    icon: SettingIcon,
    desc: 'Setting',
    link: '/setting',
    is_admin: true,
  },
]

export default defineComponent({
  name: 'TheSideBar',
  components: {
    ChevronDoubleRightIcon,
    LogoutIcon,
    LogoIcon,
    SettingIcon,
    ContainerIcon,
    DashboardIcon,
    PurchaseOrderIcon,
    ReadyToPickupIcon,
  },
  setup() {
    const isHover = ref<boolean>(false)
    const router = useRouter()
    const isAdmin = computed(() => store.getters.getRole === 0 || store.getters.getRole === 1)
    const currentRoute = computed(() => router.currentRoute.value.path)

    const isExpand = computed(() => store.getters.getIsExpand)

    const toggleFullNav = () => {
      if (isExpand.value) {
        isHover.value = !isHover.value
      }
    }
    const toggleIsExpand = () => {
      store.commit(MutationTypes.SET_IS_EXPAND, !isExpand.value)
      isHover.value = isExpand.value
    }
    const isLoginPage: Ref<boolean> = injectStrict('isLoginPage')
    const handleLogout = async () => {
      await authService.signOut()
      await router.push('/login')
    }
    return {
      isAdmin,
      isHover,
      toggleFullNav,
      navList,
      router,
      currentRoute,
      isLoginPage,
      handleLogout,
      isExpand,
      toggleIsExpand,
    }
  },
})
