
import { User } from 'firebase/auth'
import { computed, defineComponent } from 'vue'
import { Ref } from 'vue/dist/vue'

import { store } from '@/store'
import { injectStrict } from '@/utils/injection'

export default defineComponent({
  name: 'TheHeader',
  setup() {
    const isLoginPage: Ref<boolean> = injectStrict('isLoginPage')
    const currentUser = computed<User | null>(() => store.getters.getUser)
    return {
      isLoginPage,
      currentUser,
    }
  },
})
