import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5587e06a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "info-header-content",
  dir: "rtl"
}
const _hoisted_2 = { class: "info-header--user" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[_ctx.isLoginPage ? 'hidden' : ''], "info-header-container"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.currentUser)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.currentUser.email), 1))
          : (_openBlock(), _createElementBlock("p", _hoisted_4, "someone@jd.net.nz"))
      ])
    ])
  ], 2))
}