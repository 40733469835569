import firebase from 'firebase/compat'
import { GetterTree } from 'vuex'

import { RootState } from '@/store'
import { State } from '@/store/modules/user/state'

export type Getters = {
  getUser(state: State): firebase.User | null
  getToken(state: State): string | null
  getRole(state: State): 0 | 1 | 2
}

export const getters: GetterTree<State, RootState> & Getters = {
  getUser: (state) => state.user,
  getToken: (state) => state.token,
  getRole: (state) => state.role,
}
