import { GetterTree } from 'vuex'

import { RootState } from '@/store'

import { State } from './state'

export type Getters = {
  getIsExpand(state: State): boolean
}

export const getters: GetterTree<State, RootState> & Getters = {
  getIsExpand: (state) => state.isExpand,
}
