import { GridApi } from 'ag-grid-community'

import globalConfig from '@/composables/globalConfig'
import { getContainerById } from '@/services/container'
import { getPurchaseOrder } from '@/services/purchaseOrder'
import { Container, PurchaseOrder, PurchaseOrderItem } from '@/types'
import { currencyFormatter } from '@/utils/decorator'

export interface PurchaseOrderSupplier {
  id: string
  name: string
  total: number
}

export interface PurchaseOrderListFilter {
  id: string
  name: string
}

/**
 * Get supplier list from purchase order
 * @param orders
 */
export const getPurchaseOrdersSupplier = async (orders: PurchaseOrder[] | PurchaseOrderItem[]): Promise<PurchaseOrderSupplier[]> => {
  const result: PurchaseOrderSupplier[] = []
  if (orders.length > 0) {
    for (const order of orders) {
      const isExist = result.findIndex((res) => res.id === order.supplier.id)
      if (isExist !== -1) {
        result[isExist].total += 1
      } else {
        result.push({
          ...order.supplier,
          total: 1,
        })
      }
    }
  }
  return result
}

/**
 * Get Purchase Order List from order items
 * @param orders
 */
export const getRtpPurchaseOrderList = async (orders: PurchaseOrderItem[]): Promise<PurchaseOrderListFilter[]> => {
  const result: PurchaseOrderListFilter[] = []
  if (orders.length > 0) {
    const purchaseOrderList = await getPurchaseOrder()
    const poList = orders.map((order) => order.rtp_main_history.flat()).flat()
    for (const po of poList) {
      const isExist = result.findIndex((res) => res.id === po.purchase_order_id)
      if (isExist === -1) {
        const isFound = purchaseOrderList.items.findIndex((item) => item.id === po.purchase_order_id)
        if (isFound !== -1) {
          result.push({
            id: purchaseOrderList.items[isFound].id,
            name: purchaseOrderList.items[isFound].name,
          })
        }
      }
    }
    return result
  }
  return result
}

/**
 * Get On The Way container list
 * @param products
 */
export const getOtwContainerList = async (products: PurchaseOrderItem[]) => {
  const result: PurchaseOrderListFilter[] = []
  if (products.length > 0) {
    const containerList = products.map((product) => product.otw_containers).flat()
    for (const po of containerList) {
      const isExist = result.findIndex((res) => res.id === po.container_id)
      if (isExist === -1) {
        const data = await getContainerById(po.container_id)
        if (data.data.items.length > 0) {
          result.push({
            id: data.data.id,
            name: data.data.name,
          })
        }
      }
    }
  }
  return result
}

export const calculateContainerOrderTotalValue = (container: Container): string => {
  return currencyFormatter(
    container.items.reduce((acc: number, item: any) => {
      return item.order_quantity * item.supply_price + acc
    }, 0)
  )
}

export const calculateContainerOrderTotalValueSupplier = (container: Container): { supplier_name: string; total: number }[] => {
  const result = []
  for (const item of container.items) {
    const isSupplierExist = result.findIndex((res) => res.supplier_name === item.supplier.name)
    const itemTotal = item.supply_price * item.order_quantity
    if (isSupplierExist === -1) {
      result.push({
        supplier_name: item.supplier.name,
        total: itemTotal,
      })
    } else {
      result[isSupplierExist].total += itemTotal
    }
  }
  return result
}

export const calculateContainerOrderTotalValueBrand = (container: Container): { brand_name: string; total: number }[] => {
  const result = []
  for (const item of container.items) {
    const isBrandExist = result.findIndex((res) => res.brand_name === item.brand.name)
    const itemTotal = item.supply_price * item.order_quantity
    if (isBrandExist === -1) {
      result.push({
        brand_name: item.brand.name,
        total: itemTotal,
      })
    } else {
      result[isBrandExist].total += itemTotal
    }
  }
  return result
}

export const setError = (message: string, errorType: 'success' | 'info' | 'danger' | 'warning') => {
  const { setIsError, setErrorMessage, setErrorType } = globalConfig()
  setErrorMessage(message)
  setErrorType(errorType)
  setIsError(true)
  setTimeout(() => setIsError(false), 2000)
}

export const rowNodePayload = (gridApi: GridApi) => {
  const payload: PurchaseOrderItem[] = []
  gridApi.forEachNode((node) => {
    const isExist = payload.findIndex((res) => res.id === node.data.id)
    if (isExist === -1) {
      payload.push(node.data)
    } else {
      alert(`Duplicate ${node.data.variant_name}`)
    }
  })
  return payload
}
