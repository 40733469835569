import { useStore } from '@/store'
import { MutationTypes as SettingMutationType } from '@/store/modules/settings/mutation-types'
import { MutationTypes as UserMutationType } from '@/store/modules/user/mutation-types'

interface GlobalLocalStorage extends Storage {
  getItem(key: 'user' | 'settings' | 'role'): string | null
}

const store = useStore()

export const globalLocalStorage: GlobalLocalStorage = localStorage

export const getSettingsLocal = () => {
  const settings = globalLocalStorage.getItem('settings')
  const userRole = globalLocalStorage.getItem('role')
  if (userRole && (userRole === '1' || '2' || '0')) {
    // @ts-ignore
    store.commit(UserMutationType.SET_ROLE, parseInt(userRole, 10))
  }
  if (settings !== null && settings !== '{}') {
    const parsedStorage = JSON.parse(settings)
    store.commit(SettingMutationType.SET_IS_EXPAND, parsedStorage.is_expand)
  } else {
    store.commit(SettingMutationType.SET_IS_EXPAND, true)
  }
}
