export const purchaseOrderConfig = {
  statuses: [
    {
      name: 'All',
      id: 0,
    },
    {
      name: 'Pending Approval',
      id: 1,
    },
    {
      name: 'Approved (Not Sent)',
      id: 2,
    },
    {
      name: 'Sent to Supplier',
      id: 3,
    },
  ],
}

export const containerConfig = {
  status: ['All', 'Approved (Not Sent)', 'Sent', 'Received'],
  statuses: [
    {
      name: 'All',
      id: 0,
    },
    {
      name: 'Pending',
      id: 1,
    },
    {
      name: 'Approved (Not Sent)',
      id: 2,
    },
    {
      name: 'Sent to Supplier',
      id: 3,
    },
    {
      name: 'OTW',
      id: 4,
    },
    {
      name: 'Received',
      id: 5,
    },
  ],
}

export const orderViewTable = {
  ACTION_WIDTH: 200,
}

export const readyToPickConfig = {
  status: ['All', 'Completed', 'Remain'],
}
