import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import { config } from '@/config'
import { getTokenIndexedDB } from '@/helpers/authHelpers'
import { useStore } from '@/store'

const store = useStore()

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('../views/DashboardView.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('../views/LoginView.vue'),
  },
  {
    name: 'forgot password',
    path: '/forgot-password',
    component: () => import('../views/ForgotPasswordView.vue'),
  },
  {
    name: 'purchase order',
    path: '/purchase-order',
    component: () => import('../views/PurchaseOrder/PurchaseOrderView.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'purchase order create',
    path: '/purchase-order/create',
    component: () => import('../views/PurchaseOrder/PurchaseOrderCreateView.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'purchase order by id',
    path: '/purchase-order/:id',
    component: () => import('../views/PurchaseOrder/id/PurchaseOrderShowView.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    name: 'purchase order by id edit',
    path: '/purchase-order/:id/edit',
    component: () => import('../views/PurchaseOrder/id/PurchaseOrderEditView.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    name: 'ready to pick',
    path: '/ready-to-pick',
    component: () => import('../views/ReadyToPickView.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'on the way',
    path: '/on-the-way',
    component: () => import('../views/OnTheWayView.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'container',
    path: '/container',
    component: () => import('../views/Container/ContainerView.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'container by id',
    path: '/container/:id',
    component: () => import('../views/Container/id/ContainerShowView.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    name: 'container by id edit',
    path: '/container/:id/edit',
    component: () => import('../views/Container/id/ContainerEditView.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    name: 'container create',
    path: '/container/create',
    component: () => import('../views/Container/ContainerCreateView.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'settings',
    path: '/setting',
    component: () => import('../views/SettingView.vue'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const userRole = store.getters.getRole
      if (userRole === 0 || userRole === 1) next()
      else next({ path: '/' })
    },
  },
]

const router = createRouter({
  history: createWebHistory(config.ROUTER + '/'),
  routes,
  scrollBehavior: () => ({ top: 0, behavior: 'smooth' }),
})

router.beforeEach(async (to, from, next) => {
  const token = await getTokenIndexedDB()
  if (to.meta.requiresAuth && token === '') {
    next({ path: '/login', query: { redirect: to.fullPath } })
  } else {
    next()
  }
})

export default router
