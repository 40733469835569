import { reactive, watch } from 'vue'

type GlobalConfigType = {
  value: {
    readonly isLoading: boolean
    readonly isError: boolean
    readonly errorType: 'success' | 'info' | 'danger' | 'warning'
    readonly errorMessage: string
  }
  setIsLoading: (isLoading: boolean) => void
  setIsError: (isError: boolean) => void
  setErrorType: (errorType: 'success' | 'info' | 'danger' | 'warning') => void
  setErrorMessage: (errorMessage: string) => void
}

const value = reactive({
  isLoading: false,
  isError: false,
  errorType: 'success',
  errorMessage: '',
})

export default function globalConfig(): GlobalConfigType {
  // Watch isError value and hide error after 5 seconds
  watch(value, (newVal) => {
    if (newVal.isError) {
      setTimeout(() => {
        value.isError = false
      }, 5000)
    }
  })
  return {
    // @ts-ignore
    value,
    setIsLoading: (isLoading: boolean) => (value.isLoading = isLoading),
    setIsError: (isError) => (value.isError = isError),
    setErrorType: (errorType) => (value.errorType = errorType),
    setErrorMessage: (errorMessage) => (value.errorMessage = errorMessage),
  }
}
