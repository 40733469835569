import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02ad8794"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "font-medium"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.variantClass, 'alert'])
  }, [
    (_ctx.title !== '')
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.title) + ": ", 1))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.message), 1)
  ], 2))
}