import * as firebase from 'firebase/app'
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, User } from 'firebase/auth'

import globalConfig from '@/composables/globalConfig'
import { config } from '@/config'
import { store } from '@/store'
import { MutationTypes } from '@/store/modules/user/mutation-types'
import { firebaseConfig } from '@/utils/firebase'
import { setError } from '@/utils/helper'
import { instance } from '@/utils/instance'
firebase.initializeApp(firebaseConfig)

export const auth = getAuth()
const { setIsLoading, setIsError, setErrorType, setErrorMessage } = globalConfig()

export const authService = {
  // Firebase Auth
  signIn: async (email: string, password: string): Promise<User> => {
    setIsLoading(true)
    return signInWithEmailAndPassword(auth, email, password)
      .then(async (result) => {
        await auth.updateCurrentUser(result.user)
        localStorage.setItem('user', JSON.stringify(result.user))
        store.commit(MutationTypes.SET_USER, result.user)
        const uid = await authService.getUserRole()
        store.commit(MutationTypes.SET_ROLE, uid)
        setIsLoading(false)
        return result.user
      })
      .catch((error) => {
        setError(error.message, 'danger')
        setIsLoading(false)
        return error
      })
  },
  // Firebase Auth
  signOut: async () => {
    await auth.signOut()
    await auth.updateCurrentUser(null)
    localStorage.removeItem('user')
    store.commit(MutationTypes.SET_USER, null)
    store.commit(MutationTypes.SET_ROLE, 2)
    localStorage.removeItem('role')
    window.location.href = `${config.ROUTER}/login`
  },
  // Firebase Auth
  forgotPassword: async (email: string) => {
    return sendPasswordResetEmail(auth, email)
      .then(() => {
        setErrorType('success')
        setErrorMessage('Email sent')
        setIsError(true)
      })
      .catch((error) => {
        setErrorType('danger')
        setErrorMessage(error.message)
        setIsError(true)
      })
      .finally(() => {
        setInterval(() => setIsError(false), 2000)
      })
  },
  // Get user role
  getUserRole: async (): Promise<User | null> => {
    try {
      const { data } = await instance.get('/v1/auth')
      return data
    } catch (error) {
      // @ts-ignore
      setError(error.message, 'danger')
      return null
    }
  },
  updateLastLogin: async (): Promise<User | null> => {
    try {
      const { data } = await instance.post('/v1/auth/last-login')
      return data
    } catch (e) {
      // @ts-ignore
      setError(error.message, 'danger')
      return null
    }
  },
  // Firebase Auth
  getToken: async (): Promise<string | null> => {
    let token: string | null = null
    const currentUser = auth.currentUser
    if (currentUser) token = await currentUser.getIdToken(true)
    return token
  },
}
