<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 5.75C7.59 5.75 7.25 5.41 7.25 5V2C7.25 1.59 7.59 1.25 8 1.25C8.41 1.25 8.75 1.59 8.75 2V5C8.75 5.41 8.41 5.75 8 5.75Z" />
    <path d="M16 5.75C15.59 5.75 15.25 5.41 15.25 5V2C15.25 1.59 15.59 1.25 16 1.25C16.41 1.25 16.75 1.59 16.75 2V5C16.75 5.41 16.41 5.75 16 5.75Z" />
    <path
      d="M14.68 22.75H8C4.35 22.75 2.25 20.65 2.25 17V8.5C2.25 4.85 4.35 2.75 8 2.75H16C19.65 2.75 21.75 4.85 21.75 8.5V13.63C21.75 13.92 21.58 14.18 21.33 14.31C21.07 14.43 20.76 14.4 20.54 14.22C18.96 12.96 16.53 12.96 14.95 14.25C13.87 15.1 13.26 16.37 13.26 17.76C13.26 18.58 13.48 19.38 13.91 20.07C14.24 20.61 14.65 21.06 15.13 21.4C15.39 21.59 15.51 21.93 15.41 22.24C15.29 22.54 15.01 22.75 14.68 22.75ZM8 4.25C5.14 4.25 3.75 5.64 3.75 8.5V17C3.75 19.86 5.14 21.25 8 21.25H12.89C12.79 21.12 12.7 20.98 12.62 20.84C12.05 19.92 11.75 18.85 11.75 17.75C11.75 15.9 12.57 14.2 14.01 13.07C15.72 11.68 18.25 11.38 20.25 12.3V8.5C20.25 5.64 18.86 4.25 16 4.25H8Z"
    />
    <path d="M13 11.75H7C6.59 11.75 6.25 11.41 6.25 11C6.25 10.59 6.59 10.25 7 10.25H13C13.41 10.25 13.75 10.59 13.75 11C13.75 11.41 13.41 11.75 13 11.75Z" />
    <path
      d="M9.62 16.75H7C6.59 16.75 6.25 16.41 6.25 16C6.25 15.59 6.59 15.25 7 15.25H9.62C10.03 15.25 10.37 15.59 10.37 16C10.37 16.41 10.04 16.75 9.62 16.75Z"
    />
    <path
      d="M17.75 23.75C16.47 23.75 15.26 23.35 14.24 22.61C13.6 22.15 13.05 21.55 12.62 20.84C12.05 19.92 11.75 18.85 11.75 17.75C11.75 15.9 12.57 14.2 14.01 13.07C16.09 11.38 19.37 11.37 21.47 13.04C22.92 14.18 23.75 15.9 23.75 17.75C23.75 18.85 23.45 19.92 22.88 20.84C22.56 21.39 22.15 21.87 21.68 22.26C20.61 23.22 19.21 23.75 17.75 23.75ZM17.75 13.25C16.73 13.25 15.73 13.6 14.94 14.24C13.86 15.09 13.25 16.36 13.25 17.75C13.25 18.57 13.47 19.37 13.9 20.06C14.23 20.6 14.64 21.05 15.12 21.39C15.89 21.95 16.8 22.25 17.75 22.25C18.86 22.25 19.88 21.86 20.7 21.13C21.06 20.83 21.35 20.48 21.59 20.07C22.02 19.37 22.25 18.57 22.25 17.75C22.25 16.36 21.63 15.0799 20.54 14.2199C19.74 13.5799 18.78 13.25 17.75 13.25Z"
    />
    <path
      d="M17.75 21C17.34 21 17 20.66 17 20.25C17 19.29 16.21 18.5 15.25 18.5C14.84 18.5 14.5 18.16 14.5 17.75C14.5 17.34 14.84 17 15.25 17C16.21 17 17 16.21 17 15.25C17 14.84 17.34 14.5 17.75 14.5C18.16 14.5 18.5 14.84 18.5 15.25C18.5 16.21 19.29 17 20.25 17C20.66 17 21 17.34 21 17.75C21 18.16 20.66 18.5 20.25 18.5C19.29 18.5 18.5 19.29 18.5 20.25C18.5 20.66 18.16 21 17.75 21ZM17.32 17.75C17.47 17.88 17.62 18.02 17.75 18.18C17.88 18.03 18.02 17.88 18.18 17.75C18.03 17.62 17.88 17.48 17.75 17.32C17.62 17.48 17.48 17.62 17.32 17.75Z"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PurchaseOrderIcon',
})
</script>

<style scoped></style>
