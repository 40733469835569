import firebase from 'firebase/compat'
import { MutationTree } from 'vuex'

import { MutationTypes } from '@/store/modules/user/mutation-types'
import { State } from '@/store/modules/user/state'

export type Mutations<S = State> = {
  [MutationTypes.SET_USER](state: S, payload: firebase.User): void
  [MutationTypes.SET_TOKEN](state: S, payload: string): void
  [MutationTypes.SET_ROLE](state: S, payload: 0 | 1 | 2): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_USER]: (state, payload) => {
    state.user = payload
    localStorage.setItem('user', JSON.stringify(payload))
  },
  [MutationTypes.SET_ROLE]: (state, payload) => {
    state.role = payload
    localStorage.setItem('role', String(payload))
  },
  [MutationTypes.SET_TOKEN]: (state, payload) => {
    state.token = payload
    localStorage.setItem('token', payload)
  },
}
